import React from "react";
import {Box, Button, Icon, useBreakpointValue} from "@chakra-ui/react";
import {
    VscMenu as ListIcon,
    VscSymbolNumeric as GridIcon,
    VscEdit as EditIcon,
    VscTrash as DeleteIcon,
} from "react-icons/vsc";
import {usePlatformContext} from "../../../contexts/PlatformContextProvider";
import {MobileSearchActionsBar, WebSearchActionsBar} from "../../../components/search-actions-bar";

// FIXME Improve this component. It was created to centralize the logic of the topbar in the dashboard, but it was done
//  by moving the function calls it had to props. Maybe some of them can live in this component instead of being passed
export function DashboardTopbar({
                                    filterFormData,
                                    isListLayout,
                                    firstShownItem,
                                    lastShownItem,
                                    itemsCount,
                                    selectedItems,
                                    isAnyWebsiteSelected,
                                    onToggleAllCheckboxes,
                                    onToggleEssentialInformation,
                                    onOpenWebpageClicked,
                                    onUpdateWebpageClicked,
                                    onExportItems,
                                    onToggleGrid,
                                    deleteSelectedItems,
                                    showLimitedInfo,
                                }) {
    const {isWebPlatform, isMobilePlatform} = usePlatformContext();
    const updateButtonLabel = useBreakpointValue({base: "", sm: "Edit"});
    const deleteButtonLabel = useBreakpointValue({base: "", sm: "Delete"});

    const shouldEditButtonBeVisible = isWebPlatform || (isMobilePlatform && selectedItems?.length === 1);

    return (
        <>
            {isWebPlatform
                // FIXME Make a better implementation of the different topbars
                ? <WebSearchActionsBar firstShownItem={firstShownItem} lastShownItem={lastShownItem}
                                       itemsCount={itemsCount} onToggleAllCheckboxes={onToggleAllCheckboxes}
                                       onToggleEssentialInformation={onToggleEssentialInformation} showLimitedInfo={showLimitedInfo}/>
                : <MobileSearchActionsBar firstShownItem={firstShownItem} lastShownItem={lastShownItem}
                                          itemsCount={itemsCount} onToggleAllCheckboxes={onToggleAllCheckboxes}/>
            }
            <Box display="flex" gap={4} alignItems="center">
                <Box display="flex" gap={4}>
                    {selectedItems?.length ? (
                        <>
                            <Button
                                variant="outline"
                                colorScheme="red"
                                onClick={deleteSelectedItems}
                                leftIcon={!!deleteButtonLabel ? <Icon as={DeleteIcon}/> : null}
                                aria-label="Delete"
                            >
                                {deleteButtonLabel ? deleteButtonLabel : <Icon as={DeleteIcon}/>}
                            </Button>
                            {shouldEditButtonBeVisible && (
                                <Button
                                    variant="outline"
                                    colorScheme="blue"
                                    onClick={onUpdateWebpageClicked}
                                    leftIcon={!!updateButtonLabel ? <Icon as={EditIcon}/> : null}
                                    aria-label="Update"
                                >
                                    {updateButtonLabel ? updateButtonLabel : <Icon as={EditIcon}/>}
                                </Button>
                            )}
                            {isWebPlatform && isAnyWebsiteSelected &&
                                <Button
                                    variant="outline"
                                    colorScheme="blue"
                                    onClick={onOpenWebpageClicked}
                                >
                                    Open
                                </Button>
                            }
                            <Button
                                variant="outline"
                                colorScheme="blue"
                                onClick={() => {
                                    if (selectedItems && selectedItems.length) {
                                        onExportItems({
                                            ...filterFormData,
                                            itemIds: selectedItems.map(d => d.itemId).filter(d => d)
                                        })
                                    } else {
                                        onExportItems(filterFormData)
                                    }
                                }}>
                                Export
                            </Button>
                        </>
                    ) : null}
                </Box>
                {isWebPlatform && (
                    isListLayout ?
                        <Icon as={GridIcon} boxSize={6} cursor="pointer" onClick={onToggleGrid}/> :
                        <Icon as={ListIcon} boxSize={6} cursor="pointer" onClick={onToggleGrid}/>
                )}
            </Box>
        </>
    );
}